import 'firebase/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD910de3H0qbqQ5yL6MrEHUqC2FWJXjspw",
  authDomain: "website-23a07.firebaseapp.com",
  databaseURL: "https://website-23a07-default-rtdb.firebaseio.com",
  projectId: "website-23a07",
  storageBucket: "website-23a07.appspot.com",
  messagingSenderId: "524136879752",
  appId: "1:524136879752:web:26cf85dd9403400282713d",
  measurementId: "G-CB9TNBB23T"
};

export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);