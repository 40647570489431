import React from 'react';
import './Cuarto.css';

function Cuarto() {

  return (
    <>

      <div>
        <img className='cdo-image' src={require('./images/cdo.png')} alt="" />
      </div>
      <div className='cdo-title'><h1>CULTURA DE ORACIÓN Y ADORACIÓN</h1></div>

      <div className='cdo-text'><p>En Corea del Sur, Turquía, Argentina, Israel, Guatemala, México y en ciudades alrededor del mundo, la novia de Jesús está regresando a su primer amor. Casas de oración están abriéndose en muchos países del mundo para ofrecer adoración a Dios.
        En Isaías 56:7, dice "Dios dice que su casa será llamada una casa de oración".
        <br /><br /> <br />
        El cuarto de oración Tamán es un lugar dedicado a la intercesión y adoración de día y de noche. Existe como un punto estratégico en Ciudad Valles y la Huasteca Potosina donde intercesores, levitas y todos los que quieran pueden buscar a Dios en unidad.
        <br /><br /> <br />
        Todos están invitados a pasar el tiempo que gusten en el cuarto de oración. Puedes pasar tiempo orando, adorando, estudiando tu Biblia, leyendo un libro cristiano, escribiendo, o meditando. Hay libertad para estar sentado, de pie, postrado, o caminar libremente. Es un lugar santo donde nos encontramos con el Dios omnipotente.</p>

        <h3 div='cdo-text'>Dirección</h3>
        <p>Calle Alvaro Obregón #14 79000 Ciudad Valles, San Luis Potosi, Mexico</p>
        <div style={{ maxWidth: "100%" }}>
          <iframe
            title="My OpenStreetMap"
            width="100%"
            height="350"
            src="https://www.openstreetmap.org/export/embed.html?bbox=-99.01938915252687%2C21.954310291408046%2C-99.00436878204347%2C21.98738291839361&amp;layer=mapnik&amp;marker=21.97084756761592%2C-99.01187896728516"
            style={{ border: "1px solid black" }}
          ></iframe>
        </div><small>
          <a href="https://www.openstreetmap.org/?mlat=21.9708&amp;mlon=-99.0119#map=15/21.9708/-99.0119">
            Ver mapa más grande
          </a>
        </small>

      </div>

    </>
  );
}

export default Cuarto;

