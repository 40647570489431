import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <>
      <div className='footer'>Derechos Reservados © 2023 - Tamán México
        <img src={require('./images/bandera.png')} alt="" />
      </div>
    </>
  );
}

export default Footer;