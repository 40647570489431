const faqData = [{
  question: '¿Qué significa Tamán?',
  answer: 'Tamán significa "lugar del encuentro" en lenguaje Tenek.'
},
{
  question: '¿Qué es el cuarto de oración?',
  answer: 'Es un lugar designado para la búsqueda de Dios, donde el enfoque principal es Él. Dejando a un lado todo, nos posicionamos para escucharlo y para adorarlo en unidad con otros creyentes. Durante la semana, hay equipos de levitas y intercesores de iglesias locales comprometidos a mantener disponibles horarios de adoración y oración donde todos pueden asistir.'
},
{
  question: '¿Qué es un set?',
  answer: 'Es un período de 2 horas donde nos reunimos levitas, intercesores y los demás asistentes, oramos, cantamos y meditamos en la palabra de Dios unidos como hermanos.'
},
{
  question: "¿Tamán es una iglesia?",
  answer: 'No, no somos una iglesia. Nuestros miembros pertenecen a distintas iglesias, porque creemos que si hay unidad en el cuerpo de Cristo en oración, veremos el poder de Dios de manera sobrenatural en nuestra región.'
},
{
  question: '¿Quién puede asistir a la casa de oración?',
  answer: '¡Todos! Todos son bienvenidos a Tamán, de todas las edades y congregaciones. Los niños son bienvenidos en la casa de oración y en las actividades de Tamán con sus papás. Creemos en la importancia de enseñar a nuestros niños y adolescentes acerca de cómo buscar a Dios.   '
},
{
  question: '¿Qué es un levita?',
  answer: 'La Bibilia habla mucho de los levitas. Nosotros entendemos un levita como una persona con talento musical, que son adoradores de Dios en cada área de sus vidas. Su mayor deseo es ministrar al corazón de Dios y agradarle a Él, no importando si están solos o tocando frente a una multitud. Un levita muestra al pueblo cómo obedecer a Dios y llevar vidas puras delante de Él.'
},
{
  question: '¿Qué es un intercesor?',
  answer: 'Nuestro ejemplo es Cristo, quien intercede por nosotros delante de su padre. Ser intercesor es cumplir el segundo mandamiento de amar a nuestro prójimo. Un intercesor vela por los intereses de otros, usando su influencia y favor con Dios para pedir algo en favor de ellos. Intercedemos por la salvación de los perdidos, por la sanidad de las familias, por la revelación del amor de Dios en la iglesia. Adentro de Tamán, tenemos miembros que pasan al frente para interceder en el micrófono y hay muchos que nos visitan e interceden desde sus asientos. Todo creyente tiene un llamado a la intercesión.'
},
{
  question: 'Quiero ser parte del equipo, ¿cómo puedo ser levita o intercesor en Tamán?',
  answer: 'Asistiendo a los cursos de Introducción a Tamán y Arpa y Copa y luego comprometiéndose a un set de dos horas por 2 a 3 meses.'
},
{
  question: '¿Qué hacemos en el Cuarto de Oración?',
  answer: 'Usamos tres formatos para fomentar la unidad en el cuarto de oración. El primero son sets de intercesión donde hay adoración, e  intercesores de Tamán pasan al frente para orar por peticiones específicas. El segundo es la Adoración con la Palabra, donde los levitas toman pasajes bíblicos para desarrollar durante su set.  El tercer formato es Devocional donde un levita ministra a Dios a través de música mientras los demás buscan a Dios en reverencia.'
}];

export default faqData;