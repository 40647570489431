import React from 'react';
import './Historia.css';

function Historia() {
  return (
    <>
      <div>
        <img src={require('./images/historia.png')} alt='' className="historia-image" />
      </div>
      <br />
      <div className='main-text'>
        <p>Tamán es una base intercesora y misionera que tiene como visión la adoración, oración y las misiones; cuyo fundamento es el cuarto de oración, donde los levitas e intercesores buscan el rostro de Dios igual como lo hicieron Moisés y el Rey David. Sabemos que la unidad en la adoración e intercesión produce un ambiente diferente en la ciudad y en la región.
        </p>
        <h1>Historia</h1>
        <p>Por años existió la visión de tener un lugar en Ciudad Valles que estuviera dedicado a la intercesión, adoración y las misiones. Dios comenzó a juntar todas las piezas a finales del 2012.
          <br /> <br />
          En enero de 2013, un grupo de creyentes comenzamos a orar juntos para buscar la voluntad y el favor de Dios para esa visión, y el 26 de Febrero del mismo año recibimos las llaves de un lugar amplio en el centro de Valles.
          <br /> <br />
          El primer set (o reunión) se llevó a cabo el 16 de abril del 2013. Desde ese día, el incienso de intercesión ha subido desde este lugar. Nuestro deseo es tener intercesión y adoración de día y de noche sucediendo en nuestra ciudad. Dios está restableciendo su casa como una casa de oración para todas las naciones (Isaías 56:7).
          <br /> <br />
          Tamán significa "lugar de encuentro". Así como Moisés iba a la tienda de reunión para conversar con Dios, Tamán existe como un lugar dedicado para nuestro encuentro con Dios. Cuando los hijos de Dios le buscan en unidad, se produce una transformación en sus comunidades.

          <br /> <br />

          Hoy en día, la Base Misionera está conformada por la casa de oración, la comunidad creativa, clases de música, internados y la participación en eventos misioneros para capacitar a los creyentes con el propósito de extender el reino de Dios.</p>
      </div>
      <br /><br />
    </>
  );
}

export default Historia;