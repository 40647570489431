import React from 'react';

function ErrorPage() {
  return (
    <div>
      <h1> This page does not exist </h1>
    </div>
  );
}

export default ErrorPage;