import React, { useState } from 'react';
import { getDatabase, ref, push } from 'firebase/database';
import { app } from './firebase.js';
import DOMPurify from 'dompurify';
import './Peticion.css';


function Peticion() {
  const [name, setName] = useState('');
  const [request, setRequest] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const dbRef = ref(getDatabase(app), 'requests');
    push(dbRef, {
      name: DOMPurify.sanitize(name),
      request: DOMPurify.sanitize(request),
    });
    setName('');
    setRequest('');
    alert("Tu petición ha sido enviada. Oraremos por tí");
    setTimeout(() => {
      setIsSubmitting(false);
    }, 5000);
  };

  return (
    <form className='peticion-form' onSubmit={handleSubmit}>
      <label htmlFor="name">Nombre: </label>
      <br />
      <input type="text" required id='name' placeholder='Escribe tu nombre' value={name} onChange={(e) => setName(e.target.value)} maxLength={100} />
      <br /> <br />
      <label htmlFor="request">Escribe tu petición de oración: </label>
      <br />
      <textarea type="text" required id='request' placeholder='No incluyas información confidencial' value={request} onChange={(e) => setRequest(e.target.value)} maxLength={1000} />
      <br /> <br />
      <button type='submit' disabled={isSubmitting}>Enviar</button>
    </form>
  );
}

export default Peticion;