import React from 'react';
import './Valores.css';
import valores from './valoresData';

function Valores(props) {
  const valoresItems = valores.map((valor, index) => (
    <div key={index}>
      <h1>{valor.title}</h1>
      <p className='main-text'>{valor.text}</p>
    </div>
  ));
  return (
    <>
      <div>
        <img src={require('./images/valores.png')} alt='' className="valores-image" />
      </div>
      <div className='valores-container'>{valoresItems}</div>
      <br /> <br />
    </>
  );
}

export default Valores;
