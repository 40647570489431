import React from 'react';
import './Imagenes.css';

function Imagenes() {
  const images = require.context('./images/imagesPage', false, /\.(png|JPG|jpe?g)$/);
  const imageList = images.keys().map((imageName) => (
    <img className='images-item' key={imageName} src={images(imageName)} alt={imageName} />
  ));

  return (
    <div className='images-list'>
      {imageList}
    </div>
  );
}

export default Imagenes;
