import React from 'react';
import './Videos.css';

function Videos() {
  return (
    <div className='videos-mainPage'>
      <div>
        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/nS9o5gYR93Y" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='10 años'></iframe>
        <p>Celebrando 10 años de Tamán</p>
      </div>

      <div>
        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/wbmFYZHYHB4" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='second video'></iframe>
        <p>Noche de adoración entre amigos</p>
      </div>

      <div>
        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/wsLOnec0zL4" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='second video'></iframe>
        <p>Adorando desde casa en familia</p>
      </div>

      <div>
        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/nWUVmBafA6Y" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='second video'></iframe>
        <p>Canción Firme Amor</p>
      </div>

      <div>
        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/4fsgqOwI-JI" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='second video'></iframe>
        <p>Adorando desde La Huasteca Potosina</p>
      </div>

      <div>
        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/1-LESnMesBE" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='second video'></iframe>
        <p>El equipo de Creativos pintando un mural en la ciudad</p>
      </div>

      <div>
        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/xTxFxe24QTk" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='second video'></iframe>
        <p>El Señor habita en el canto de los levitas</p>
      </div>

      <div>
        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/yOCglkVDp7E" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='second video'></iframe>
        <p>Adorando a Nuestro Señor Jesucristo</p>
      </div>

      <div>
        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/RYCe02ZCz2I" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='second video'></iframe>
        <p>Adorando a nuestro Señor entre amigos</p>
      </div>

      <div>
        <iframe width="100%" height="300px" src="https://www.youtube.com/embed/9WnVDRAI6e8" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen title='second video'></iframe>
        <p>La familia se reúne para honrar a nuestro Señor</p>
      </div>
      <br /><br />

    </div>
  );
}

export default Videos;