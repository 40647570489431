import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import './Navbar.css';
import Faq from './Faq';
import ErrorPage from './ErrorPage';
import Cuarto from './Cuarto';
import Recursos from './Recursos';
import Unete from './Unete';
import Videos from './Videos';
import Imagenes from './Imagenes';
import Historia from './Historia';
import Valores from './Valores';
import Peticion from './Peticion';

function App() {

  return (
    <>
      <div className='logo'>
        <img className='logo' src="./TAMAN.png" alt='logo' />
      </div>
      <hr />
      <Router>
        <div>
          <nav>
            <ul className='nav-links'>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link>Base Misionera</Link>
                <ul className='dropdown'>
                  <li><Link to='/historia'>Historia</Link></li>
                  <li><Link to='/valores'>Nuestros Valores</Link></li>
                  <li><Link to='/faq'>Preguntas Frecuentes</Link></li>
                  <li><Link to='/peticion'>Petición de Oracion</Link></li>
                </ul>
              </li>
              <li>
                <Link>Casa de Oración</Link>
                <ul className='dropdown'>
                  <li><Link to='/cuarto'>Casa de Oración</Link></li>
                  <li><Link to='/unete'>Únete al equipo</Link></li>
                  <li><Link to='/recursos'>Recursos</Link></li>
                </ul>
              </li>
              <li>
                <Link>Multimedia</Link>
                <ul className='dropdown'>
                  <li><Link to='/videos'>Videos</Link></li>
                  <li><Link to='/imagenes'>Imágenes</Link></li>
                </ul>
              </li>
            </ul>

          </nav>

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path='/historia' element={<Historia />} />
            <Route path='/valores' element={<Valores />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/cuarto' element={<Cuarto />} />
            <Route path='/recursos' element={<Recursos />} />
            <Route path='/unete' element={<Unete />} />
            <Route path='/videos' element={<Videos />} />
            <Route path='/imagenes' element={<Imagenes />} />
            <Route path='/peticion' element={<Peticion />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
