import Card from './Card';
import './Home.css';

function Home() {
  return (
    <>
      <div className='homepage'>
        <h1>Estableciendo una cultura de <br />
          adoración y oración <br />
          para alcanzar las naciones</h1>
      </div>

      <div className='main-verse'>
        <h3><em>
          "Y hablaba el Señor con Moisés cara a cara, <br /> como quien habla con un amigo. <br /> Después de eso, Moisés regresaba al campamento; <br /> pero Josué, su joven asistente, <br /> nunca se apartaba de la Tienda de reunión."</em>
          <h3>​Éxodo 33:11</h3>
        </h3>
        <br />
      </div>

      <div className='last-part'>
        <h2>BASE MISIONERA</h2>
        <div className="card-container">
          <Card
            title='Casa de Oración'
            image={require('./images/casa.JPG')}
            onError={(e) => console.log('Error loading image:', e)}
          />
          <Card
            title='Clases de Música'
            image={require('./images/clases2.png')}
            onError={(e) => console.log('Error loading image:', e)}
          />
          <Card
            title='Comunidad Creativa'
            image={require('./images/comunidad.jpg')}
            onError={(e) => console.log('Error loading image:', e)}
          />
        </div>
      </div>
    </>
  );
}

export default Home;