// import React, { useState, useEffect } from 'react';
// import { getDatabase, ref, onValue, push } from 'firebase/database';
// import { app } from "./firebase.js";
// import './Recursos.css';

// function Recursos() {
//   const [recursos, setRecursos] = useState([]);
//   const [inputValue, setInputValue] = useState('');

//   useEffect(() => {
//     const dbRef = ref(getDatabase(app), 'recursos');
//     onValue(dbRef, (snapshot) => {
//       const data = snapshot.val();
//       if (data) {
//         const recursosList = Object.values(data);
//         setRecursos(recursosList);
//       }
//     });
//   }, []);

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleSubmit = () => {
//     const dbRef = ref(getDatabase(app), 'recursos');
//     push(dbRef, {
//       content: inputValue,
//     });
//     setInputValue('');
//   };

//   return (
//     <>
//       <div>
//         <img className='recursos-image' src={require('./images/recursos.png')} alt="" />
//         <ul>
//           {recursos.map((recurso, index) => (
//             <li key={index}>{recurso.content}</li>
//           ))}
//         </ul>
//         <input type="text" value={inputValue} onChange={handleInputChange} />
//         <button onClick={handleSubmit}>Submit</button>
//       </div>
//     </>
//   );
// }

// export default Recursos;



// this one renders images

// import React, { useState, useEffect } from 'react';
// import { getDatabase, ref, onValue, push } from 'firebase/database';
// import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
// import { app } from "./firebase.js";
// import './Recursos.css';

// function Recursos() {
//   const [recursos, setRecursos] = useState([]);
//   const [inputValue, setInputValue] = useState('');
//   const [imageUrl, setImageUrl] = useState(null);

//   useEffect(() => {
//     const dbRef = ref(getDatabase(app), 'recursos');
//     onValue(dbRef, (snapshot) => {
//       const data = snapshot.val();
//       if (data) {
//         const recursosList = Object.values(data);
//         setRecursos(recursosList);
//       }
//     });
//   }, []);

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleSubmit = () => {
//     const dbRef = ref(getDatabase(app), 'recursos');
//     push(dbRef, {
//       content: inputValue,
//     });
//     setInputValue('');
//   };

//   const handleImageClick = () => {
//     const storage = getStorage();

//     // Reference to the image file in Firebase Storage
//     const imageRef = storageRef(storage, "fotos.jpg");
//     getDownloadURL(imageRef)
//       .then((url) => {
//         console.log("Image URL:", url);
//         setImageUrl(url);
//       })
//       .catch((error) => {
//         console.error("Error getting image URL:", error);
//       });
//   };

//   return (
//     <>
//       <div>
//         {imageUrl ? (
//           <img className='recursos-image' src={imageUrl} alt="" />
//         ) : (
//           <button onClick={handleImageClick}>Load Image</button>
//         )}
//         <ul>
//           {recursos.map((recurso, index) => (
//             <li key={index}>{recurso.content}</li>
//           ))}
//         </ul>
//         <input type="text" value={inputValue} onChange={handleInputChange} />
//         <button onClick={handleSubmit}>Submit</button>
//       </div>
//     </>
//   );
// }

// export default Recursos;




//this one opens the file in a new window

// import React, { useState, useEffect } from 'react';
// import { getDatabase, ref, onValue, push } from 'firebase/database';
// import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
// import { app } from "./firebase.js";
// import './Recursos.css';
// import { Document, Page } from 'react-pdf';

// function Recursos() {
//   const [recursos, setRecursos] = useState([]);
//   const [inputValue, setInputValue] = useState('');
//   const [pdfUrl, setPdfUrl] = useState(null);
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   useEffect(() => {
//     const dbRef = ref(getDatabase(app), 'recursos');
//     onValue(dbRef, (snapshot) => {
//       const data = snapshot.val();
//       if (data) {
//         const recursosList = Object.values(data);
//         setRecursos(recursosList);
//       }
//     });
//   }, []);

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleSubmit = () => {
//     const dbRef = ref(getDatabase(app), 'recursos');
//     push(dbRef, {
//       content: inputValue,
//     });
//     setInputValue('');
//   };

//   const handlePdfClick = () => {
//     const storage = getStorage();

//     // Reference to the PDF file in Firebase Storage
//     const pdfRef = storageRef(storage, "guia.pdf");
//     getDownloadURL(pdfRef)
//       .then((url) => {
//         console.log("PDF URL:", url);
//         window.open(url, '_blank');
//       })
//       .catch((error) => {
//         console.error("Error getting PDF URL:", error);
//       });
//   };


//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

//   function changePage(offset) {
//     setPageNumber(prevPageNumber => prevPageNumber + offset);
//   }

//   function previousPage() {
//     changePage(-1);
//   }

//   function nextPage() {
//     changePage(1);
//   }

//   return (
//     <>
//       <div>
//         {pdfUrl ? (
//           <>
//             <div className='pdf-container'>
//               <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
//                 <Page pageNumber={pageNumber} />
//               </Document>
//             </div>
//             <div className='pdf-controls'>
//               <p>Page {pageNumber} of {numPages}</p>
//               <button disabled={pageNumber <= 1} onClick={previousPage}>Previous</button>
//               <button disabled={pageNumber >= numPages} onClick={nextPage}>Next</button>
//             </div>
//           </>
//         ) : (
//           <button onClick={handlePdfClick}>Load PDF</button>
//         )}
//         <ul>
//           {recursos.map((recurso, index) => (
//             <li key={index}>{recurso.content}</li>
//           ))}
//         </ul>
//         <input type="text" value={inputValue} onChange={handleInputChange} />
//         <button onClick={handleSubmit}>Submit</button>
//       </div>
//     </>
//   );
// }

// export default Recursos;





// THIS ONE WORKS!!!

// import React, { useState, useEffect } from 'react';
// import { getDatabase, ref, onValue, push } from 'firebase/database';
// import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
// import { app } from "./firebase.js";
// import './Recursos.css';
// import { Document, Page } from 'react-pdf';

// function Recursos() {
//   const [recursos, setRecursos] = useState([]);
//   const [inputValue, setInputValue] = useState('');
//   const [pdfUrl, setPdfUrl] = useState(null);
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   useEffect(() => {
//     const dbRef = ref(getDatabase(app), 'recursos');
//     onValue(dbRef, (snapshot) => {
//       const data = snapshot.val();
//       if (data) {
//         const recursosList = Object.values(data);
//         setRecursos(recursosList);
//       }
//     });
//   }, []);

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleSubmit = () => {
//     const dbRef = ref(getDatabase(app), 'recursos');
//     push(dbRef, {
//       content: inputValue,
//     });
//     setInputValue('');
//   };

//   const handlePdfClick = () => {
//     const storage = getStorage();

//     // Reference to the PDF file in Firebase Storage
//     const pdfRef = storageRef(storage, "guia.pdf");
//     getDownloadURL(pdfRef)
//       .then((url) => {
//         console.log("PDF URL:", url);
//         const embedUrl = `https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`;
//         window.open(embedUrl, '_blank');
//       })
//       .catch((error) => {
//         console.error("Error getting PDF URL:", error);
//       });
//   };

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

//   function changePage(offset) {
//     setPageNumber(prevPageNumber => prevPageNumber + offset);
//   }

//   function previousPage() {
//     changePage(-1);
//   }

//   function nextPage() {
//     changePage(1);
//   }

//   return (
//     <>
//       <div>
//         {pdfUrl ? (
//           <>
//             <div className='pdf-container'>
//               <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
//                 <Page pageNumber={pageNumber} />
//               </Document>
//             </div>
//             <div className='pdf-controls'>
//               <p>Page {pageNumber} of {numPages}</p>
//               <button disabled={pageNumber <= 1} onClick={previousPage}>Previous</button>
//               <button disabled={pageNumber >= numPages} onClick={nextPage}>Next</button>
//             </div>
//           </>
//         ) : (
//           <a href="https://docs.google.com/gview?url=https%3A%2F%2Ffirebasestorage.googleapis.com%2Fv0%2Fb%2Fwebsite-23a07.appspot.com%2Fo%2Fguia.pdf%3Falt%3Dmedia%26token%3D831a08b6-12e4-4b05-b534-6c5a7e2b7deb&embedded=true">Load PDF</a>
//         )}
//         <ul>
//           {recursos.map((recurso, index) => (
//             <li key={index}>{recurso.content}</li>
//           ))}
//         </ul>
//         <input type="text" value={inputValue} onChange={handleInputChange} />
//         <button onClick={handleSubmit}>Submit</button>
//       </div>
//     </>
//   );
// }

// export default Recursos;





import React, { useState, useEffect } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { app } from './firebase.js';
import './Recursos.css';

function Recursos() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const storage = getStorage(app);
    const storageRef = ref(storage);

    listAll(storageRef)
      .then((res) => {
        const promises = res.items.map((item) =>
          getDownloadURL(item).then((url) => ({ url, name: item.name }))
        );
        Promise.all(promises).then((files) => setFiles(files));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <img className='recursos-image' src={require('./images/recursos.png')} alt="" />
      <h3>Aquí podrás descargar archivos en formato PDF</h3>
      {files.map((file) => (
        <div key={file.name}>
          <a href={file.url} target="_blank" rel="noopener noreferrer">
            {file.name}
          </a>
        </div>
      ))}
    </div>
  );
}

export default Recursos;
