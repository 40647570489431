import React from 'react';
import faqData from './faqData';
import './Faq.css';

function Faq(props) {
  const faqsItems = faqData.map((faq, index) => (
    <div key={index}>
      <h1>{faq.question}</h1>
      <p className='faq-main'>{faq.answer}</p>
    </div>
  ));
  return (
    <>
      <div>
        <img src={require('./images/preguntas.png')} alt=""
          className='faq-image'
        />
      </div>
      <div className='faq-container'>{faqsItems}
      </div>
      <br /><br /><br />
    </>
  );
}

export default Faq;