const valores = [
  {
    title: 'CULTURA',
    text: 'La cultura del Reino de Dios es lo que nos une como creyentes. A pesar de nuestras diferencias, tenemos lo esencial en común: adoración, oración y hacer discípulos. Amar a Dios con todo lo que somos y lo que tenemos nos une y nos hace familia. Es nuestra meta compartida honrar a Dios y conocerlo más. La unidad de la iglesia es una muestra que Dios es lo primordial para nosotros y trae el reino de Dios a nuestras comunidades.'
  },
  {
    title: 'ADORACIÓN',
    text: 'Al ver a Dios, la adoración brota de nosotros por quien es Él. Sus atributos, su grandeza y sus obras nos dejan en asombro acerca de quién es nuestro Dios. Es nuestro placer y una necesidad adorarlo y reconocemos que Él es más grande que nosotros. Le damos el mejor lugar porque Él es perfecto y fuimos creados para adorarlo.'
  },
  {
    title: 'ORACIÓN',
    text: 'Reconocer quién es Dios nos inspira a conocerlo más. La oración es simplemente la conversación entre tú y tu creador que trae libertad a tu vida. Dios busca amigos, hijos y  familia que le amen. Nos rescató del pecado por su misericordia y amor y es el privilegio más grande de nuestras vidas poder conocer su corazón y obedecerlo.'
  },
  {
    title: 'MISIONES',
    text: 'Las misiones no son un llamado exclusivo para unos pocos, sino que son para cada hijo de Dios. Simplemente compartimos quién es Dios con otros usando los medios y talentos que Él nos ha dado para que ellos también puedan conocerlo. Extendemos la cultura de Dios a través de nuestro arte, conducta, amor, fidelidad, amor y palabras reconociendo que nuestra obediencia abre camino al Espíritu Santo para traer convicción y salvación.'
  }];

export default valores;