import React from 'react';
import './Card.css';

function Card(props) {
  return (
    <div className='column'>
      <div className='card'>
        <h3>{props.title}</h3>
        <img src={props.image} alt={props.title} />
      </div>
    </div>
  );
}

export default Card;