import React from 'react';
import './Unete.css';

function Unete() {
  return (
    <>
      <div>
        <img className='unete-image' src={require('./images/unete.png')} alt="" />
      </div>
      <div className='unete-main'>
        <h2>INTERCESORES Y LEVITAS</h2>
        <p>Gracias a la dedicación de todos los que dan su tiempo para servir en la casa de oración, podemos mantener sets de intercesión y adoración durante la semana. Ellos tienen el anhelo de ministrar al corazón de Dios e interceder por nuestra ciudad y el mundo. El equipo de Tamán está conformado por miembros de múltiples congregaciones y los sets de la casa de oración están abiertos para todos los que quieran asistir.</p>
        <h2>¿CÓMO FORMAR PARTE DEL EQUIPO?</h2>
        <ol>
          <li>Asistir a la clase "conociendo Tamán"</li>
          <li>Llenar la solicitud</li>
          <li>Tomar el curso de Arpa & Copa</li>
          <li>Asistir fielmente a una iglesia local</li>
          <li>Entrevista y audición (levitas)</li>
        </ol>
        <p>Para más información acerca de cómo ser parte del equipo, escríbenos a tamanvalles@gmail.com</p>

        <h2>NIVELES DE PARTICIPACIÓN</h2>
        <p>Para los interesados, tenemos los siguientes niveles de participación:</p>
        <strong><p>Nivel 1 - Familia</p></strong>
        <ul>
          <li>2 horas por semana en la casa de oración</li>
          <li>2 horas de apoyo por semana</li>
        </ul>
        <strong><p>Misionero - Medio Tiempo</p></strong>
        <ul><li>2 horas por semana en la casa de oración</li>
          <li>8 horas de apoyo por semana</li>
        </ul>
        <strong><p>Nivel 2 - Comunidad</p></strong>
        <ul><li>6 horas por semana en el cuarto de oración</li>
          <li>2 horas de apoyo por semana</li>
        </ul>
        <strong><p>Misionero - Tiempo Completo</p></strong>
        <ul><li>24 horas por semana en la casa de oración</li>
          <li>16 horas de apoyo por semana</li></ul>
        <h2>MISIONEROS INTERCESORES</h2>
        <p><em>"Había también una profetisa, Ana, hija de Penuel, de la tribu de Aser. Era muy anciana; casada de joven, había vivido con su esposo siete años, y luego permaneció viuda hasta la edad de ochenta y cuatro. Nunca salía del templo, sino que día y noche adoraba a Dios con ayunos y oraciones. Llegando en ese mismo momento, Ana dio gracias a Dios y comenzó a hablar del niño a todos los que esperaban la redención de Jerusalén." </em>Lucas 2:36-28.</p>

        <p>​​Igual como Ana, los misioneros intercesores están comprometidos a un estilo de vida de ayuno y oración. Entregan sus vidas para ministrarle a Dios en la Casa de Oración y para servir en la comunidad. Son misioneros que son financiados por iglesias e individuos para estar en el lugar de intercesión y clamar por nuestra ciudad y el mundo. Por medio de la intercesión y adoración le damos derecho a Dios de habitar en Valles; y eso cambia el ambiente espiritual en la ciudad. Los misioneros intercesores se dedican a pedirle a Dios por más obreros para las mies (Lucas 10:2).</p>
      </div >
      <br /><br /><br />
    </>
  );
}

export default Unete;;